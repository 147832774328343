<!--
 * @Description: 维护物料和组合
 * @Author: ChenXueLin
 * @Date: 2021-11-18 15:01:04
 * @LastEditTime: 2023-01-31 11:24:04
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="维护物料和组合"
    :visible="materialCombinationVisible"
    width="1000px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    v-loading="dialogLoading"
    :before-close="handleClose"
    custom-class="createDispatchDialog"
  >
    <el-table :data="tableData" class="table" border>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in materielColumnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row, $index }">
          <span v-if="column.fieldName === 'newMaterialNo'">
            <!--  isDeliverInstall：1送装2寄装-->
            <!--  :disabled="taskFirstClass == 3 && isDeliverInstall == 2" -->
            <e6-vr-select
              v-if="
                row.isReplaceMaterial &&
                  isDeliverInstall == 1 &&
                  taskSource == 5
              "
              v-model="row.newMaterialNo"
              :data="row.replaceDetails"
              placeholder="物料名称"
              title="物料名称"
              :props="{
                id: 'replaceMaterialNo',
                label: 'replaceMaterialName'
              }"
              @change="
                (val, node) => {
                  handleChange(val, node, row);
                }
              "
            ></e6-vr-select>
            <span v-else>{{ row.materialName }}</span>
          </span>
          <span v-else-if="column.fieldName === 'combinationId'">
            <e6-vr-select
              v-if="row.materialType != 1 && row.constAllNum"
              v-model="row.combinationId"
              :data="combinationList"
              placeholder="组合名称"
              title="组合名称"
              :disabled="taskFirstClass == 3"
              clearable
              :props="{
                id: 'combinationId',
                label: 'combinationName'
              }"
              @change="
                (val, node) => {
                  changeCombination(val, node, row);
                }
              "
            ></e6-vr-select>
            <span v-else>{{ row.combinationName }}</span>
          </span>
          <span v-else-if="column.fieldName === 'useNumber'">
            <el-input
              v-if="
                row.isReplaceMaterial &&
                  isDeliverInstall == 1 &&
                  taskSource == 5
              "
              placeholder="请输入"
              v-model="row.useNumber"
              @input="
                value => {
                  handleInput(value, row, $index);
                }
              "
              style="width:60px"
            >
            </el-input>
            <span v-else>{{ row.useNumber }}</span>
          </span>
          <span v-else-if="column.fieldName === 'isReplaceMaterialType'">
            <span>{{ row.constAllNum ? row.isReplaceMaterialType : "" }}</span>
          </span>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        label="操作"
        width="80"
        v-if="!(taskFirstClass == 3 && isDeliverInstall == 2)"
      >
        <template slot-scope="{ row, $index }">
          <i
            class="e6-icon-add_line1"
            title="新增"
            @click="handleAdd(row, $index)"
            v-if="
              row.isReplaceMaterial &&
                row.taskId &&
                row.constAllNum > 1 &&
                isDeliverInstall == 1 &&
                !row.disabled
            "
          ></i>
          <i
            class="e6-icon-clear_fill"
            title="删除"
            v-if="!row.constAllNum && row.isReplaceMaterial"
            @click="deleteColumn($index, row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit"> 确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  setMaterial,
  getMaterialWithReplace,
  getFirstCombinationList
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "materialCombination",
  components: {},
  data() {
    return {
      tableData: [], // 表格数据
      dialogLoading: false,
      combinationList: [], //组合下拉框数据
      materielColumnData: [
        {
          fieldName: "isReplaceMaterialType",
          display: true,
          fieldLabel: "类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "combinationId",
          display: true,
          fieldLabel: "组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "newMaterialNo",
          display: true,
          fieldLabel: "物料名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "constAllNum",
          display: true,
          fieldLabel: "安装总数",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "useNumber",
          display: true,
          fieldLabel: "物料数量",
          width: 90,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialTypeName",
          display: true,
          fieldLabel: "设备类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      countMap: {}
    };
  },
  //isDeliverInstall:1送装2寄装
  props: [
    "materialCombinationVisible",
    "taskId",
    "isDeliverInstall",
    "taskFirstClass",
    "taskSource"
  ],
  mixins: [base],
  computed: {},
  watch: {
    materialCombinationVisible(val) {
      if (val) {
        this.initData();
      }
    }
  },
  created() {},
  methods: {
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          getMaterialWithReplace({ taskId: this.taskId }),
          getFirstCombinationList({ id: this.taskId })
        ];
        let [tableRes, combinationRes] = await Promise.all(promiseList);
        let tableData = tableRes.data;
        let countMap = {};
        tableData.map(item => {
          if (!item.isReplaceMaterial) return;
          if (countMap[item.parentId]) {
            let val = countMap[item.parentId];
            countMap[item.parentId] = val + item.allAmount * 1;
          } else {
            countMap[item.parentId] = item.allAmount ? item.allAmount * 1 : 0;
          }
        });
        this.countMap = countMap;
        tableData.map(item => {
          item.newMaterialName = item.materialNo;
          item.newMaterialNo = item.materialNo;
          item.combinationId = item.combinationId ? item.combinationId : "";
          item.useNumber = item.allAmount * 1;
          item.disabled = item.useNumber == 1 ? true : false;
          item.replaceDetails.unshift({
            firstClassId: item.firstClassId,
            firstClassName: item.firstClassName,
            replaceMaterialName: item.materialName,
            replaceMaterialNo: item.materialNo,
            replaceMaterialType: item.materialType,
            replaceMaterialTypeName: item.materialTypeName,
            secondClassId: item.secondClassId,
            secondClassName: item.secondClassName,
            thirdClassId: item.thirdClassId,
            thirdClassName: item.thirdClassName
          });
          return item;
        });
        this.tableData = tableData;
        this.combinationList = combinationRes.data;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //计算数量
    calculateCount(parentId) {
      let tableData = this.tableData;
      let filterArr = tableData.filter(
        item => item.parentId == parentId && !item.constAllNum
      );
      let $total = filterArr.reduce((total, currentValue) => {
        return total + currentValue.useNumber;
      }, 0);
      tableData.map(item => {
        if (item.parentId == parentId && item.constAllNum) {
          item.useNumber = this.countMap[parentId] - $total;
          item.disabled = this.countMap[parentId] <= $total + 1 ? true : false;
        }
      });
      this.tableData = tableData;
    },
    //点击添加可替换物料
    handleAdd(row, index) {
      let tableData = this.tableData;
      let obj = {
        materialNo: row.materialNo,
        newMaterialNo: row.newMaterialNo,
        isReplaceMaterial: true,
        useNumber: 1,
        productId: row.productId,
        itemProductId: row.itemProductId,
        parentId: row.parentId,
        firstClassName: row.firstClassName,
        secondClassName: row.secondClassName,
        materialTypeName: row.materialTypeName,
        materialType: row.materialType,
        combinationId: row.combinationId,
        replaceDetails: row.replaceDetails,
        productMaterialId: row.productMaterialId,
        productNumber: row.productNumber
      };
      tableData.splice(index + 1, 0, obj);
      this.tableData = tableData;
      this.calculateCount(row.parentId);
    },
    //删除单行可替换物料
    deleteColumn(index, row) {
      let tableData = _.cloneDeep(this.tableData);
      tableData.splice(index, 1);
      this.tableData = tableData;
      this.calculateCount(row.parentId);
    },
    countTotal(arr, keyName, row, index) {
      let $total = 0;
      $total = arr.reduce(function(total, currentValue, currentIndex) {
        if (currentIndex == index) {
          return total * 1;
        } else {
          return row.parentId == currentValue.parentId
            ? total * 1 + currentValue[keyName] * 1
            : total;
        }
      }, 0);
      return $total;
    },

    //输入数量
    handleInput(value, row) {
      // index
      // value = value.replace(/^0|[^0-9]/g, "");
      row.useNumber = value.replace(/^0|[^0-9]/g, "");
      // let tableData = this.tableData;
      //除去当前行的输入数量后的其他同类的数量综合
      // const countTotal = this.countTotal(tableData, "useNumber", row, index);
      //总的安装数
      let total = this.countMap[row.parentId];
      // console.log(total, countTotal, countTotal + row.useNumber * 1, "!!!!!!!");
      // row.useNumber =
      //   total < countTotal + row.useNumber * 1 ? 0 : row.useNumber;
      let filterArr = this.tableData.filter(
        item => item.parentId == row.parentId
      );
      let len = filterArr.length;
      row.useNumber = total == len ? 1 : row.useNumber;

      this.calculateCount(row.parentId);
    },
    //修改可替换物料
    handleChange(val, node, row) {
      let {
        firstClassId,
        firstClassName,
        replaceMaterialName,
        replaceMaterialNo,
        secondClassId,
        secondClassName
      } = node;
      row.firstClassId = firstClassId;
      row.firstClassName = firstClassName;
      row.materialName = replaceMaterialName;
      row.newMaterialName = replaceMaterialName;
      row.newMaterialNo = replaceMaterialNo;
      row.materialNo = replaceMaterialNo;
      row.secondClassId = secondClassId;
      row.secondClassName = secondClassName;
      if (row.materialType == 1) {
        //主机需要重构组合
        let combinationList = this.combinationList;
        combinationList.map(item => {
          if (item.combinationId == row.combinationId) {
            item.combinationName = row.materialName;
          }
        });
        this.$nextTick(() => {
          this.combinationList = [...combinationList];
        });
      }
    },
    //修改组合
    changeCombination(val, node, row) {
      row.combinationName = node.combinationName;
      let tableData = this.tableData;
      tableData.map(item => {
        if (item.itemProductId == row.itemProductId) {
          item.combinationId = node.combinationId;
        }
      });
      this.tableData = tableData;
    },
    //点击确定新增
    handleSubmit() {
      let numberMap = {};
      let tableData = this.tableData;
      tableData.map(item => {
        if (!item.isReplaceMaterial) return;
        if (numberMap[item.parentId]) {
          let val = numberMap[item.parentId];
          numberMap[item.parentId] = val + item.useNumber * 1;
        } else {
          numberMap[item.parentId] = item.useNumber ? item.useNumber * 1 : 0;
        }
      });
      for (let key in numberMap) {
        if (numberMap[key] != this.countMap[key]) {
          this.$message.warning(
            "可替换物料的所有安装数量不等于安装总数，请修改后重新提交"
          );
          return;
        }
      }
      this.setMaterialReq();
    },
    //设置物料名称
    async setMaterialReq() {
      try {
        this.dialogLoading = true;
        let tableData = _.cloneDeep(this.tableData);
        let productList = [];
        var arrayTwo = tableData.reduce((res, item) => {
          res[item.itemProductId]
            ? res[item.itemProductId].push({
                productId: item.productId,
                materialsNo: item.materialNo,
                combinationId: item.combinationId,
                constAllNum: item.constAllNum || "",
                allAmount: item.useNumber || item.allAmount,
                materialsName: item.materialName,
                productNumber: item.productNumber,
                combinationName: item.combinationName,
                parentId: item.parentId
              })
            : (res[item.itemProductId] = [
                {
                  productId: item.productId,
                  materialsNo: item.materialNo,
                  combinationId: item.combinationId,
                  constAllNum: item.constAllNum || "",
                  allAmount: item.useNumber || item.allAmount,
                  materialsName: item.materialName,
                  productNumber: item.productNumber,
                  combinationName: item.combinationName,
                  parentId: item.parentId
                }
              ]);
          return res;
        }, {});
        console.log(arrayTwo, "arr-----");
        for (let key in arrayTwo) {
          let obj = {
            itemProductId: key,
            productNumber: arrayTwo[key][0].productNumber,
            materialsList: arrayTwo[key],
            productId: arrayTwo[key][0].productId
          };
          productList.push(obj);
        }

        let res = await setMaterial({
          taskId: this.taskId,
          productList
        });
        if (res.code == "OK") {
          this.$message.success("设置成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.tableData = [];
      this.$emit("handleClose", "materialCombinationVisible");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding-top: 20px;
    .table {
      [class^="e6-icon"] {
        height: 28px;
        color: #46bfea;
        line-height: 28px;
        font-style: normal;
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        border-radius: 3px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        padding: 0;
        font-size: 20px;
      }
    }
  }
}
</style>
