var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],attrs:{"title":"维护物料和组合","visible":_vm.materialCombinationVisible,"width":"1000px","close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"before-close":_vm.handleClose,"custom-class":"createDispatchDialog"}},[_c('el-table',{staticClass:"table",attrs:{"data":_vm.tableData,"border":""}},[_vm._l((_vm.materielColumnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(column.fieldName === 'newMaterialNo')?_c('span',[(
              row.isReplaceMaterial &&
                _vm.isDeliverInstall == 1 &&
                _vm.taskSource == 5
            )?_c('e6-vr-select',{attrs:{"data":row.replaceDetails,"placeholder":"物料名称","title":"物料名称","props":{
              id: 'replaceMaterialNo',
              label: 'replaceMaterialName'
            }},on:{"change":function (val, node) {
                _vm.handleChange(val, node, row);
              }},model:{value:(row.newMaterialNo),callback:function ($$v) {_vm.$set(row, "newMaterialNo", $$v)},expression:"row.newMaterialNo"}}):_c('span',[_vm._v(_vm._s(row.materialName))])],1):(column.fieldName === 'combinationId')?_c('span',[(row.materialType != 1 && row.constAllNum)?_c('e6-vr-select',{attrs:{"data":_vm.combinationList,"placeholder":"组合名称","title":"组合名称","disabled":_vm.taskFirstClass == 3,"clearable":"","props":{
              id: 'combinationId',
              label: 'combinationName'
            }},on:{"change":function (val, node) {
                _vm.changeCombination(val, node, row);
              }},model:{value:(row.combinationId),callback:function ($$v) {_vm.$set(row, "combinationId", $$v)},expression:"row.combinationId"}}):_c('span',[_vm._v(_vm._s(row.combinationName))])],1):(column.fieldName === 'useNumber')?_c('span',[(
              row.isReplaceMaterial &&
                _vm.isDeliverInstall == 1 &&
                _vm.taskSource == 5
            )?_c('el-input',{staticStyle:{"width":"60px"},attrs:{"placeholder":"请输入"},on:{"input":function (value) {
                _vm.handleInput(value, row, $index);
              }},model:{value:(row.useNumber),callback:function ($$v) {_vm.$set(row, "useNumber", $$v)},expression:"row.useNumber"}}):_c('span',[_vm._v(_vm._s(row.useNumber))])],1):(column.fieldName === 'isReplaceMaterialType')?_c('span',[_c('span',[_vm._v(_vm._s(row.constAllNum ? row.isReplaceMaterialType : ""))])]):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),_vm._v(" "),(!(_vm.taskFirstClass == 3 && _vm.isDeliverInstall == 2))?_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
              var $index = ref.$index;
return [(
            row.isReplaceMaterial &&
              row.taskId &&
              row.constAllNum > 1 &&
              _vm.isDeliverInstall == 1 &&
              !row.disabled
          )?_c('i',{staticClass:"e6-icon-add_line1",attrs:{"title":"新增"},on:{"click":function($event){return _vm.handleAdd(row, $index)}}}):_vm._e(),_vm._v(" "),(!row.constAllNum && row.isReplaceMaterial)?_c('i',{staticClass:"e6-icon-clear_fill",attrs:{"title":"删除"},on:{"click":function($event){return _vm.deleteColumn($index, row)}}}):_vm._e()]}}],null,false,1042107638)}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }